import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import ReactDOM from 'react-dom';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import * as CoreModals from 'core/modules/Modals/_can';
import { useLocation } from 'react-router-dom';
import { getParamsFromHash } from 'core/utils/helpers/getParamsFromHash';
import { useHandleInstoreModals } from 'core/modules/Instore2/hooks/useHandleInstoreModals';
import { useModal } from '../ModalProvider';

function ModalRoot() {
  const { modal, openModal, closeModal } = useModal();
  const location = useLocation();
  const hashParams = getParamsFromHash(location.hash);

  useHandleInstoreModals();

  useEffect(() => {
    const { modalType, ...modalParams } = hashParams;

    if (modalType) {
      openModal({ name: modalType, params: modalParams, options: { wasTriggeredFromUrl: true } });
    }
  }, []);

  if (isEmpty(modal)) {
    return null;
  }

  const Component = CoreModals[modal.name];

  if (!Component) {
    return null;
  }

  return (
    ReactDOM.createPortal(
      <Component modal={modal} openModal={openModal} closeModal={closeModal} />,
      document.getElementById('app'),
    )
  );
}

export default (props) => <ComponentErrorBoundary><ModalRoot {...props} /></ComponentErrorBoundary>;
