import 'core/utils/publicPath';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import RoutesWrap from 'core/routes';
import { Provider } from 'react-redux';
import { MediaQueries } from 'core/modules/MediaQueries/MediaQueries';
import ModalRoot from 'core/modules/Modal/_can/ModalRoot';
import ModalProvider from 'core/modules/Modal/ModalProvider';

import './styles/_can/style.scss';

import getStore from './store';
import { routesConfig } from './routes';

const store = getStore(true);

const render = () => {
  const container = document.createElement('div');
  container.id = 'app';
  document.body.appendChild(container);

  const root = createRoot(container);

  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <MediaQueries>
          <ModalProvider>
            <RoutesWrap orgRoutesConfig={routesConfig} />
            <ModalRoot />
          </ModalProvider>
        </MediaQueries>
      </BrowserRouter>
    </Provider>,
  );
};

render();
