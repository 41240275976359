import { all, call } from 'redux-saga/effects';
import values from 'lodash/values';

import ocapi from 'core/sagas/ocapi';
import member from 'core/sagas/member';
import appSaga from 'core/modules/App/sagas';
import instoreSaga from 'core/modules/Instore2/sagas';

const combinedSagas = {
  ocapi,
  member,
  appSaga,
  instoreSaga,
};

export default function* rootSaga() {
  yield all(values(combinedSagas).map(saga => call(saga)));
}
