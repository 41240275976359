import React from 'react';
import favoriteIconSvg from 'org/assets/images/icons/favorite.inline.svg';
import starsSVG from 'org/assets/images/onboarding/stars.svg';
import mobileCongratsImage from 'org/assets/images/onboarding/mobileCongratulations.svg';
import desktopCongratsImage from 'org/assets/images/onboarding/desktopCongratulations.svg';

export const favoritesIcon = {
  defaults: {
    favoriteIconViewBox: '-1 0 42 35',
    FavoriteIconSvg: favoriteIconSvg,
  },
};

export const searchBar = {
  defaults: {
    getPlaceholder: () => ('Find a store or product'),
  },
};

export const searchForm = {
  defaults: {
    searchButtonClassName: 'mn_SVGIconDesktopSearch',
    shouldShowResultLinkOutside: true,
    shouldUsePopUpOnMobile: false,
  },
};

export const adBlockWarning = {
  defaults: {
    getWarningMessage: () => (
      `The use of ad blockers may prevent you from earning Mileage Plan miles on your shopping.
      While shopping with Mileage Plan Shopping,
      turn off any ad blockers or use a different browser to ensure you earn miles as expected.`
    ),
  },
};

export const navCategoriesTOF = {
  defaults: {
    allStoresLinkText: 'See all stores',
  },
};

export const recentStores = {
  defaults: {
    viewData: {
      footerCTATitle: 'View all recent stores',
    },
  },
};

export const quickSearch = {
  defaults: {
    isPremierRebate: true,
  },
};

export const navBanner = {
  defaults: {
    bannerText: {
      button: {
        title: 'Earn miles with the push of a button.',
        text: () => <>Get the Mileage Plan<sup>&trade;</sup> Shopping button and never miss out on miles.</>,
      },
      favorites: {
        title: 'Pick favorites',
        text: () => <>Pick your favorite stores and get email alerts when they offer extra miles.</>,
        ctaTitle: 'Pick Favorites',
      },
    },
  },
};

export const creditCardMarketingBanner = {
  defaults: {
    shouldShowCreditCardImage: false,
    shouldShowButtonView: false,
  },
};

export const instoreLoginLink = {
  defaults: {
    useCallbackCookie: true,
  },
};

export const instoreAddNewCard = {
  defaults: {
    iframeTheme: 'cartera-alaska-pc-form',
  },
};

export const navCategoryFeatured = {
  defaults: {
    rebateOptions: {
      hideElevationCurrency: false,
      hideRebatePrefix: true,
    },
  },
};

export const navCategoryFlyout = {
  defaults: {
    shouldShowMerchantName: true,
    rebateOptions: {
      hideRebatePrefix: true,
      hideElevationCurrency: false,
      hideNewValuePrefix: true,
      rebateAdditionalPrefix: true,
      tiered: {
        rebateAdditionalPrefix: true,
        hideElevationCurrency: false,
      },
    },
  },
};

export const merchantOffer = {
  defaults: {
    showExtraRewardsPill: false,
    getCopyButtonText: () => ('Copy Code'),
    rebateOptions: {
      hideElevationCurrency: false,
    },
  },
};

export const firstFavoriteModal = {
  defaults: {
    titleText: 'Success.',
    getBodyText: (siteUrl) => (
      <>
        Keep picking your favorite stores and we'll email you when they offer extra miles.
        Manage alert preferences at
        {' '}
        <a className="mn_myAccountLink" href={`${siteUrl}/ai____.htm`}>My account</a>.
      </>
    ),
    buttonText: 'OKAY',
  },
};

export const onboardingWelcome = {
  defaults: {
    getWelcomeText: (storeCount) => (
      <>
        Here are a few tips for earning the most award miles when you
        shop at {storeCount}+ stores.
      </>
    ),
    welcomeCTAText: 'Next',
  },
};

export const onboardingFavorites = {
  defaults: {
    favoritesHeading: 'Pick 5 or more favorite stores.',
    turnOffText: 'To turn alerts off, visit the My Account page.',
  },
};

export const onboardingButton = {
  defaults: {
    viewData: {
      getButtonHeader: () => (
        <>Activate miles directly at store sites with the Mileage Plan Shopping button.</>
      ),
      secondBulletText: 'Compare mileage rates in search results',
      customThirdBulletText: 'Discover new stores that offer miles per $',
      usePeriod: true,
      starsSvg: starsSVG,
      mobileButtonHeader: 'Add the Mileage Plan Shopping mobile button to your phone.',
      mobileButtonDescription:
        'With the mobile button easily activate miles and apply ' +
        'coupons while shopping on your phone. Available for iOS 15+.',
      mobileButtonCTA: 'Download for iOS',
      mobileButtonLaterText: 'No thanks.',
    },
  },
};

export const onboardingMobileCongratulation = {
  defaults: {
    getCongratsText: () => ('You\'re all set to start shopping and earning miles.'),
    image: mobileCongratsImage,
  },
};

export const headerOnboardingCongratulations = {
  defaults: {
    getCongratulationText: () => ('You\'re all set to start shopping and earning miles.'),
    image: desktopCongratsImage,
  },
};

export const emailOptInSubscribeModal = {
  defaults: {
    earnType: 'miles',
    cancelText: 'No thanks. I don\'t want to receive emails',
    emailOptions: {
      email_news_optin: 1,
      email_odotw_optin: 1,
      email_deals_optin: 1,
      email_global_optout: 0,
    },
  },
};
