import Loadable from 'react-loadable';

const LoadableWrapper = pageComponentPath => Loadable({
  loader: () => import(`./pages${pageComponentPath}`),
  loading: () => (null),
});

export const routesConfig = [
  { route: '/h____.htm?', PageComponent: LoadableWrapper('/Home') },
  { route: '/ai____.htm', PageComponent: LoadableWrapper('/Profile') },
  { route: '/p/:dynamicPage', PageComponent: LoadableWrapper('/DynamicRewards') },
  { route: '/me____.htm', PageComponent: LoadableWrapper('/MerchantExperience') },
  { route: '/instore', PageComponent: LoadableWrapper('/Instore') },
];
