import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import { gaEventTracker } from 'core/utils/analytics/analytics';
import loadInitialState from 'core/utils/state/initialState';
import LocalStorageCache from 'core/utils/cache/localStorage';
import rootSaga from './sagas';
import rootSagaCan from './sagas/_can';
import reducers from './reducers';
import gaActionEvents from './utils/analytics/ga-events';

const isProductionMode = process.env.NODE_ENV === 'production';
const localStorageCache = new LocalStorageCache();
const enableReduxDevTools = !isProductionMode || localStorageCache.getItem('enableReduxDevTools');
const composeEnhancers = enableReduxDevTools ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;
const sagaMiddleware = createSagaMiddleware();
const middleware = [gaEventTracker(gaActionEvents), sagaMiddleware];

if (!isProductionMode) {
  middleware.push(createLogger({ collapsed: true }));
}

export default (isCan) => {
  const store = createStore(reducers, loadInitialState(), composeEnhancers(applyMiddleware(...middleware)));
  isCan ? sagaMiddleware.run(rootSagaCan) : sagaMiddleware.run(rootSaga);
  return store;
};
